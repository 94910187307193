<template>
    <el-dialog title="审核" :visible.sync="audit.visible" :close-on-click-modal="false"
               destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit" >
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="操作人">{{form.caoZuoRen}}</el-form-item>
            <el-form-item label="操作人ID">{{form.caoZuoRenID}}</el-form-item>
            <el-form-item label="调拨门店">{{form.shenQingMenDian}}</el-form-item>
            <el-form-item label="货至门店">{{form.huoZhiMenDian}}</el-form-item>
            <el-form-item label="申请时间">{{form.shenQingSJ}}</el-form-item>
            <el-form-item label="出库时间">{{form.chuKuSJ}}</el-form-item>
            <el-form-item label="操作时间">{{form.caoZuoSJ}}</el-form-item>
            <el-form-item label="申请合计">{{form.shenQingHeJi}}</el-form-item>
            <el-form-item label="应收合计">{{form.yingShouHeJi}}</el-form-item>
            <el-form-item label="应收金额">{{form.yingShouJinE}}</el-form-item>
            <el-form-item label="实收合计">{{form.shiShouHeJi}}</el-form-item>
            <el-form-item label="实收金额">{{form.shiShouJinE}}</el-form-item>
            <el-form-item label="拒收合计">{{form.juShouHeJi}}</el-form-item>
            <el-form-item label="拒收金额">{{form.juShouJinE}}</el-form-item>
            <el-form-item label="状态">
                <x-dict-show :code="form.zhuangTai" dictType="T_HUO_LIU_SHEN_HE_STATUS"/>
            </el-form-item>
            <el-form-item label="备注">{{form.beiZhu}}</el-form-item>
            <el-form-item label="备用">{{form.beiYong}}</el-form-item>
<!--            <x-divider title="审核信息"></x-divider>
            <el-form-item label="申请人">{{form.applicant}}</el-form-item>
            <el-form-item label="申请时间" class="w66">{{form.applyDate}}</el-form-item>
            <el-form-item label="审核意见" class="line">
                <el-input v-model="form.auditMind" type="textarea" maxlength="100" show-word-limit />
            </el-form-item>-->
        </el-form>
        <x-divider title="调拨申请详情"></x-divider>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="pass" icon="el-icon-check">通 过</el-button>
            <el-button type="danger" @click="reject" icon="el-icon-close">驳 回</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
        <el-table :data="form.diaoBoShenQingXiangQings" border class="x-detail-table">
            <el-table-column width="60" label="序号" prop="sort" />
            <el-table-column width="150" prop="shenQingMenDian" label="申请门店" />
            <el-table-column width="150" prop="huoZhiMenDian" label="货至门店" />
            <el-table-column width="150" prop="chuKuSL" label="数量" />
            <el-table-column width="150" prop="shenQingSJ" label="申请时间" />
            <el-table-column width="150" prop="shangPinMC" label="商品名称" />
            <el-table-column width="150" prop="shangPinID" label="商品ID" />
            <el-table-column width="150" prop="guiGe" label="规格" />
            <el-table-column width="150" prop="huoHao" label="货号" />
            <el-table-column width="150" prop="tiaoMa" label="条码" />
            <el-table-column width="150" prop="danWei" label="单位" />

        </el-table>
    </el-dialog>
</template>

<script>
    import {getAudit, audit} from "@/service/hlgl/DiaoBoShenQing";
    import {ruleBuilder} from '@/util/validate';
    import XAuditBase from "@/components/x/XAuditBase";
    import {checkboxList} from "@/service/mdgl/MenDianXX";
    export default {
        mixins: [XAuditBase],
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                auditMind: []
            };
            this.getAuditService = getAudit;
            this.auditService = audit;
            this.defaultForm = {
                id: null,
                caoZuoRen: "", // 操作人
                caoZuoRenID: "", // 操作人ID
                shenQingDeMenDian: "", // 申请的门店
                shenQingSJ: "", // 申请时间
                chuKuSJ: "", // 出库时间
                caoZuoSJ: "", // 操作时间
                shenQingHeJi: "", // 申请合计
                yingShouHeJi: "", // 应收合计
                yingShouJinE: "", // 应收金额
                shiShouHeJi: "", // 实收合计
                shiShouJinE: "", // 实收金额
                juShouHeJi: "", // 拒收合计
                juShouJinE: "", // 拒收金额
                zhuangTai: "", // 状态
                beiZhu: "", // 备注
                beiYong: "", // 备用
                applicant: "", // 申请人
                applyDate: "", // 申请时间
                auditMind: "", // 审核意见
                auditStatus: "", // 审核状态
                diaoBoShenQingXiangQings: [],
            };
        },
        data() {
            return {
                options:[],
                spxxs:[],
            }
        },
        mounted() {
            this.getMenDianOptions();
        },
        methods: {
            //获取门店列表
            getMenDianOptions() {
                checkboxList().then((response) => {
                    this.options = response.data
                })
            },

        },
    }
</script>

<style scoped>

</style>