<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
<!--            <x-search-item label="申请的门店" >
                <el-input v-model="search.shenQingDeMenDian" size="small" />
            </x-search-item>-->
            <x-search-item label="申请时间" textWidth="230px" hasEnd>
                <el-date-picker v-model="search.shenQingSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.shenQingSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="状态" >
                <x-selector-one v-model="search.zhuangTai" size="small" dictType="T_HUO_LIU_SHEN_HE_STATUS"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column label="序号" type="index" fixed/>
            <el-table-column  prop="caoZuoRen" label="操作人" show-overflow-tooltip/>
            <el-table-column  prop="shenQingMenDian" label="调拨门店" show-overflow-tooltip/>
            <el-table-column  prop="huoZhiMenDian" label="货至门店" show-overflow-tooltip/>
            <el-table-column  prop="shenQingSJ" label="申请时间" show-overflow-tooltip/>
            <el-table-column  prop="chuKuSJ" label="出库时间" show-overflow-tooltip/>
            <el-table-column  prop="caoZuoSJ" label="操作时间" show-overflow-tooltip/>
            <el-table-column  prop="shenQingHeJi" label="申请合计" show-overflow-tooltip/>
            <el-table-column  prop="yingShouHeJi" label="应收合计" show-overflow-tooltip/>
            <el-table-column  prop="yingShouJinE" label="应收金额" show-overflow-tooltip/>
            <el-table-column  prop="shiShouHeJi" label="实收合计" show-overflow-tooltip/>
            <el-table-column  prop="shiShouJinE" label="实收金额" show-overflow-tooltip/>
            <el-table-column  prop="juShouHeJi" label="拒收合计" show-overflow-tooltip/>
            <el-table-column  prop="juShouJinE" label="拒收金额" show-overflow-tooltip/>
            <el-table-column  prop="zhuangTai" label="状态" >
                <x-dict-show slot-scope="{row}" :code="row.zhuangTai" dictType="T_HUO_LIU_SHEN_HE_STATUS" />
            </el-table-column>
            <el-table-column  prop="beiZhu" label="备注" show-overflow-tooltip/>
            <el-table-column  align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleAudit(row.id)">审核</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <audit ref="audit" :audit="audit" @submitted="refresh"/>
    </div>
</template>
<script>
    import {auditList} from "@/service/hlgl/DiaoBoShenQing";
    import XTableAudit from "@/components/x/XTableAudit";
    import Audit from "@/view/audit/DiaoBoShenQingAudit";

    export default {
        name: "DiaoBoShenQingAuditList",
        mixins: [XTableAudit],
        components: {Audit},
        data() {
            this.refreshService = auditList;
            return {
                search: {
                    caoZuoRen: '',
                    shenQingDeMenDian: '',
                    shenQingSJBegin: '',
                    shenQingSJEnd: '',
                    zhuangTai: '',
                    title: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>